<template>
  <v-dialog v-model="dialog" :disabled="loading" persistent width="500">
    <v-form ref="formData" v-model="valid" onSubmit="return false;">
      <v-card :loading="loading" class="custom-card-bg-gradient">
        <dialog-header :title="title" @close="dialog = false" />
        <v-card-text>
          <v-select v-model="type" :items="types" dense label="Select type" outlined placeholder="Type" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :loading="loading" color="primary" rounded @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import { createVectorQAQC, updateFeature } from '@/api/qaqc-api'

export default {
  components: { DialogHeader },
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      title: '',
      type: undefined,
      geometry: undefined,
      editFeature: undefined,
      currentVector: undefined,
    }
  },
  props: {
    types: { type: Array, default: () => [] },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.editFeature = undefined
        this.currentVector = undefined
        this.type = undefined
        this.geometry = undefined
        this.title = ''
      }
    },
  },
  methods: {
    openDialog(vector, title, geometry, editFeature) {
      this.currentVector = vector
      this.geometry = geometry
      this.title = title
      this.editFeature = editFeature
      this.type = editFeature ? editFeature.properties.type : undefined
      this.dialog = true
    },
    save() {
      if (this.editFeature) this.updateFeature()
      else this.createFeature()
    },
    async createFeature() {
      try {
        this.loading = true
        await createVectorQAQC({
          aoiId: this.currentVector.aoi_uuid,
          projectId: this.$route.params.id,
          vectorId: this.currentVector.uuid,
          params: {
            geometry: this.geometry,
            properties: { type: this.type },
          },
        })
        this.dialog = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async updateFeature() {
      try {
        this.loading = true
        await updateFeature({
          id: this.currentVector.aoi_uuid,
          projectId: this.$route.params.id,
          vectorId: this.currentVector.uuid,
          featureId: this.editFeature.id,
          params: {
            geometry: this.geometry,
            type: this.type,
            showable: this.editFeature.showable,
          },
        })
        this.dialog = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
