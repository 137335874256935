<template>
  <v-layout class="fill-height" column style="position: relative; overflow-y: hidden">
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-layout class="fill-height overflow-y-hidden">
      <div v-show="leftMenu" style="width: 400px; height: 100%; flex: none">
        <v-layout column fill-height>
          <div style="flex: 1">
            <v-card :disabled="editing" class="AOI-menu custom-card-bg-1" height="100%" width="100%">
              <v-layout class="fill-height" column>
                <div style="flex: 1">
                  <v-btn class="map-overview-menu" color="bgContainer" fab @click="leftMenu = false">
                    <v-icon size="26">icon-chevrons_left</v-icon>
                  </v-btn>
                  <LayerControl
                    ref="layerControl"
                    :current-layers.sync="layers"
                    comparable
                    map-control
                    removable
                    transparent
                    @changeCompare="show => $refs.mapView.changeCompare(show)"
                    @changeToSyncMap="status => ($refs.mapView.mapSync = status)"
                    @changeVisible="layer => $refs.mapView.changeVisibility(layer.id)"
                    @changeVisibleLeftLayer="(layer, isSync) => $refs.mapView.changeVisibleLeftLayer(layer.id, isSync)"
                    @reloadMap="() => $refs.mapView.reloadMap()"
                    @removeAllLayer="() => $refs.mapView.removeAllLayer()"
                    @removeLayer="layerId => $refs.mapView.removeLayer(layerId)"
                    @updateStyleProperties="layerId => $refs.mapView.updateStyleProperties(layerId)"
                    @zoomTo="bbox => $refs.mapView.submitZoom(bbox)"
                  />
                </div>
              </v-layout>
            </v-card>
          </div>
        </v-layout>
      </div>
      <div class="pl-4" style="flex: 1; position: relative">
        <v-chip
          v-if="currentVector"
          class="elevation-4"
          color="primary"
          style="position: absolute; top: 12px; left: 42%; z-index: 2"
        >
          <div class="text-truncate" style="max-width: 200px; text-align: center">
            <v-layout align-center class="fill-height">
              <v-icon class="mr-2">icon-edit_square</v-icon>
              <div class="text-truncate" style="width: 175px; text-align: center">
                Edit Result of {{ currentVector.aoi_name }}
              </div>
            </v-layout>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" icon x-small v-bind="attrs" v-on="on" @click="closeEdit">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-chip>
        <v-card class="custom-card-bg-1" height="100%" width="100%">
          <v-btn
            v-if="!leftMenu"
            color="bgContainer"
            fab
            style="position: absolute; bottom: 12px; left: 12px; z-index: 2"
            @click="leftMenu = true"
          >
            <v-icon>icon-layers</v-icon>
          </v-btn>
          <v-btn
            v-if="!isCreating && currentVector && !editing"
            color="primary"
            small
            style="position: absolute; top: 12px; left: 12px; z-index: 2"
            @click="isCreating = true"
          >
            <v-icon class="mr-1" small>mdi-plus</v-icon>
            Add new
          </v-btn>
          <v-btn-toggle v-if="editing || isCreating" style="position: absolute; top: 12px; left: 60px; z-index: 2">
            <v-btn small width="120" @click="saveFeature">
              <v-icon class="mr-1" small>mdi-content-save-outline</v-icon>
              Save
            </v-btn>
            <v-btn small width="120" @click="cancelEdit">
              <v-icon class="mr-1" small>mdi-cancel</v-icon>
              Cancel
            </v-btn>
          </v-btn-toggle>
          <Map
            ref="mapView"
            :current-layers.sync="layers"
            :inspect="!isCreating && !editing"
            :isDraw="isCreating && !editing"
          />
        </v-card>
      </div>
      <div v-if="!currentVector" class="qa-qc-index" style="width: 400px; height: 100%; flex: none">
        <v-card class="custom-card-bg-1 pa-2" height="100%" width="100%">
          <v-card class="px-3 py-0 custom-card-bg-1" height="80" width="100%">
            <v-layout align-center class="fill-height">
              <v-layout align-center column style="flex: 1">
                <h4 style="color: var(--v-info-base) !important;">Total</h4>
                <h4>{{ overview.total }}</h4>
              </v-layout>
            </v-layout>
          </v-card>
          <v-layout class="my-2" style="height: fit-content">
            <div class="pr-1" style="flex: 1">
              <v-card class="px-3 py-0 custom-card-bg-1" height="80" width="100%">
                <v-layout align-center class="fill-height">
                  <v-layout align-center column style="flex: 1">
                    <h4 style="color: var(--v-success-base) !important;">Published</h4>
                    <h4>{{ overview.published }}</h4>
                  </v-layout>
                </v-layout>
              </v-card>
            </div>
            <div class="pl-1" style="flex: 1">
              <v-card class="px-3 py-0 custom-card-bg-1" height="80" width="100%">
                <v-layout align-center class="fill-height">
                  <v-layout align-center column style="flex: 1">
                    <h4 style="color: var(--v-warning-base) !important;">Unpublish</h4>
                    <h4>{{ overview.total - overview.published }}</h4>
                  </v-layout>
                </v-layout>
              </v-card>
            </div>
          </v-layout>
        </v-card>
      </div>
    </v-layout>
    <div class="pt-4" style="width: 100%; height: 300px; overflow-y: hidden; flex: none">
      <v-card class="custom-card-bg-1" height="100%" width="100%">
        <v-data-table
          v-if="currentVector"
          :headers="editHeaders"
          :items="features"
          :loading="dataLoading"
          :options.sync="options"
          :server-items-length="totalData"
          class="hide-per-page table-small-text"
          fixed-header
          height="226"
        >
          <template v-slot:footer.prepend>
            <v-layout align-center class="fill-height pt-2">
              <v-menu v-model="advSearch" :close-on-content-click="false" offset-y top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-filter-plus</v-icon>
                  </v-btn>
                </template>
                <v-card class="pa-3" width="350">
                  <v-layout column>
                    <div style="flex: 1">
                      <v-layout align-center class="fill-height" column>
                        <div class="pb-2" style="width: 100%">
                          <v-layout align-center>
                            <span class="mr-2">Id</span>
                            <v-divider />
                          </v-layout>
                        </div>
                        <div style="width: 100%">
                          <v-layout>
                            <div class="pr-1" style="flex: 1">
                              <v-text-field
                                v-model="id"
                                clearable
                                dense
                                hide-details
                                label="Id"
                                outlined
                                placeholder="id"
                              ></v-text-field>
                            </div>
                          </v-layout>
                        </div>
                        <div class="pb-2" style="width: 100%">
                          <v-layout align-center>
                            <span class="mr-2">Area</span>
                            <v-divider />
                          </v-layout>
                        </div>
                        <div style="width: 100%">
                          <v-layout>
                            <div class="pr-1" style="flex: 1">
                              <v-text-field
                                v-model.number="fromArea"
                                :max="toArea"
                                :min="0"
                                dense
                                hide-details
                                label="From"
                                outlined
                                placeholder="From"
                                suffix="ha"
                                type="number"
                              ></v-text-field>
                            </div>
                            <div class="pl-1" style="flex: 1">
                              <v-text-field
                                v-model.number="toArea"
                                :min="fromArea ? fromArea : 0"
                                dense
                                hide-details
                                label="To"
                                outlined
                                placeholder="To"
                                suffix="ha"
                                type="number"
                              ></v-text-field>
                            </div>
                          </v-layout>
                        </div>
                        <div class="pb-2" style="width: 100%">
                          <v-layout align-center>
                            <span class="mr-2">Type</span>
                            <v-divider />
                          </v-layout>
                        </div>
                        <div style="width: 100%">
                          <v-layout>
                            <div class="pr-1" style="flex: 1">
                              <v-select
                                v-model="type"
                                :items="types"
                                clearable
                                dense
                                hide-details
                                label="Type"
                                outlined
                                placeholder="Type"
                              ></v-select>
                            </div>
                          </v-layout>
                        </div>
                      </v-layout>
                    </div>
                    <div class="pt-2" style="flex: none">
                      <v-layout align-center class="fill-height" justify-end>
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            :color="hover ? 'primary' : 'rgba(25,102,247,0.31)'"
                            style="border-radius: 8px"
                            @click="getChangeDetectionData(currentVector, true)"
                          >
                            <v-icon class="mr-1">icon-search</v-icon>
                          </v-btn>
                        </v-hover>
                      </v-layout>
                    </div>
                  </v-layout>
                </v-card>
              </v-menu>
            </v-layout>
          </template>
          <template #[`item.area`]="{item}">
            <div v-html="transformArea(item.area)"></div>
          </template>
          <template #[`item.properties.type`]="{item}">
            <div v-html="item.properties.type"></div>
          </template>
          <template #[`item.showable`]="{item}">
            <v-switch
              v-model="item.showable"
              class="mt-0"
              color="success"
              dense
              hide-details
              @change="updateShowable(item)"
            ></v-switch>
          </template>
          <template #[`item.action`]="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="dataLoading || loading"
                  color="info"
                  icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="editAOI(item)"
                >
                  <v-icon>icon-edit</v-icon>
                </v-btn>
              </template>
              <span>Edit Feature</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="dataLoading || loading" icon x-small v-bind="attrs" v-on="on" @click="addLayer(item)">
                  <v-icon>icon-gps</v-icon>
                </v-btn>
              </template>
              <span>Highlight</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-data-table
          v-else
          :headers="headers"
          :items="changeData"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalData"
          class="hide-per-page table-small-text"
          fixed-header
          height="226"
        >
          <template v-slot:footer.prepend>
            <v-layout align-center class="fill-height pt-2">
              <div style="width: 275px">
                <v-select
                  v-model="aoi"
                  :items="AOIs"
                  clearable
                  dense
                  hide-details
                  item-text="name"
                  label="Select AOI"
                  outlined
                  placeholder="AOI"
                  return-object
                  @change="getVector"
                />
              </div>
              <div class="ml-2" style="width: 275px">
                <v-select
                  v-model="status"
                  :items="listStatus"
                  clearable
                  dense
                  hide-details
                  item-text="name"
                  item-value="value"
                  label="Status"
                  outlined
                  placeholder="Status"
                  @change="getVector"
                ></v-select>
              </div>
            </v-layout>
          </template>
          <template #[`item.published`]="{item}">
            <v-switch
              v-model="item.published"
              class="mt-0"
              color="success"
              dense
              hide-details
              @change="updateVector(item)"
            ></v-switch>
          </template>
          <template #[`item.action`]="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="info" icon x-small v-bind="attrs" v-on="on" @click="openEditVector(item)">
                  <v-icon>icon-edit</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="item.view ? 'success' : ''"
                  icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="changeView(item)"
                >
                  <v-icon>icon-eye</v-icon>
                </v-btn>
              </template>
              <span>View</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <SaveFeature ref="saveForm" :types.sync="types" />
  </v-layout>
</template>
<script>
import Map from '@/components/Map.vue'
import { getChangeDetectionLayer, getFeature, getVector } from '@/api/change-detection-api'
import { getOverviewQAQC, getTypeVector, getVectorQAQC, updateFeature, updateVectorQAQC } from '@/api/qaqc-api'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import DialogHeader from '@/components/DialogHeader.vue'
import bbox from '@turf/bbox'
import sleep from '@/utils/sleep'
import AreaConvert from '@/utils/textArea'
import VectorStyle from '@/utils/VectorStyle'
import { mapState } from '@/store/ults'
import DatePicker from '@/components/DatePicker.vue'
import SaveFeature from '@/views/QA&QC/SaveFeature.vue'

export default {
  computed: {
    ...mapState('AOI', ['AOIs']),
  },
  components: { SaveFeature, DatePicker, DialogHeader, LayerControl, Map },
  data() {
    return {
      advSearch: false,
      listStatus: [
        {
          name: 'Published',
          value: true,
        },
        {
          name: 'Unpublished',
          value: false,
        },
      ],
      status: undefined,
      aoi: undefined,
      id: undefined,
      fromArea: undefined,
      toArea: undefined,
      types: [],
      type: undefined,
      oldLayers: [],
      editFeature: undefined,
      dataLoading: false,
      currentVector: undefined,
      features: [],
      leftMenu: true,
      overview: {},
      isCreating: false,
      editing: false,
      loading: false,
      totalData: 0,
      changeData: [],
      options: { sortBy: ['acquired_date'], sortDesc: [true] },
      editHeaders: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'properties.type',
        },
        {
          text: 'Area',
          align: 'start',
          sortable: true,
          value: 'area',
        },
        {
          text: 'Showable',
          align: 'start',
          sortable: false,
          value: 'showable',
        },
        {
          text: 'Action',
          align: 'center',
          sortable: false,
          value: 'action',
        },
      ],
      headers: [
        {
          text: 'AOI Name',
          align: 'start',
          sortable: false,
          value: 'aoi_name',
        },
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'type',
        },
        {
          text: 'Features Count',
          align: 'start',
          sortable: true,
          value: 'features_count',
        },
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'acquired_date',
        },
        {
          text: 'Published',
          align: 'start',
          sortable: false,
          value: 'published',
        },
        {
          text: 'Action',
          align: 'center',
          sortable: false,
          value: 'action',
        },
      ],
      layers: [],
    }
  },
  watch: {
    advSearch(val) {
      if (!val) this.getChangeDetectionData(this.currentVector, true)
    },
    editing(val) {
      if (!val) {
        this.$refs.mapView.deleteAllDraw()
      }
    },
    options: {
      handler() {
        if (this.currentVector) {
          this.getChangeDetectionData(this.currentVector)
        } else this.getVector()
      },
      deep: true,
    },
    currentVector(val) {
      this.options.page = 1
      this.options.sortBy = []
      this.editing = false
      this.$refs.mapView.reSize()
      if (!val) this.options = { sortBy: ['acquired_date'], sortDesc: [true] }
    },
    leftMenu() {
      this.$refs.mapView.reSize()
    },
  },
  mounted() {
    this.getListAOI()
    this.getVector()
    this.getOverview()
  },
  methods: {
    cancelEdit() {
      this.editing = false
      this.isCreating = false
    },
    async getListAOI() {
      try {
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: {},
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    closeEdit() {
      this.isCreating = false
      this.currentVector = undefined
      this.$refs.mapView.removeAllLayer()
      this.layers = [...this.oldLayers]
      this.$nextTick(() => {
        this.$refs.mapView.reloadMap()
        this.getVector()
      })
    },
    async getVector() {
      try {
        this.loading = true
        const res = await getVectorQAQC({
          projectId: this.$route.params.id,
          payload: {
            aoi_uuid: this.aoi ? this.aoi.uuid : undefined,
            published: this.status,
            page: this.options.page,
            per_page: this.options.itemsPerPage,
            sort: this.options.sortBy[0]
              ? !this.options.sortDesc[0]
                ? this.options.sortBy[0]
                : `-${this.options.sortBy[0]}`
              : undefined,
          },
        })
        this.changeData = res.data.data
        this.$refs.mapView.removeAllLayer()
        this.changeData = res.data.data
        this.totalData = res.data.total
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getOverview() {
      try {
        const res = await getOverviewQAQC({ projectId: this.$route.params.id })
        this.overview = res.data
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    async updateVector(vector) {
      try {
        await updateVectorQAQC({
          projectId: this.$route.params.id,
          aoiId: vector.aoi_uuid,
          vectorId: vector.uuid,
          params: {
            published: vector.published,
          },
        })
        await this.getOverview()
        await this.getVector()
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    changeView(vector) {
      this.$refs.mapView.addVectorTiles({
        isHover: true,
        data: vector,
        tiles: [vector.tile_info.tile_url],
        bounds: vector.tile_info.bbox,
        layerName: 'default',
        paint: VectorStyle.getStyle(vector.tile_info.styles),
        name: vector.aoi_name + '-' + vector.date,
        id: vector.aoi_name + '-' + vector.date,
        type: vector.tile_info.styles.type,
      })
    },
    async openEditVector(vector) {
      try {
        this.currentVector = vector
        this.loading = true
        this.features = []
        await this.getChangeDetectionData(vector)
        this.oldLayers = [...this.layers]
        this.$refs.mapView.removeAllLayer()
        await this.getLayers()
        await this.getType(vector)
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getType(vector) {
      try {
        const res = await getTypeVector({
          id: vector.aoi_uuid,
          projectId: this.$route.params.id,
          vectorId: vector.uuid,
          payload: {
            field: 'type',
          },
        })
        this.types = res.data
      } catch (e) {
      } finally {
      }
    },
    async getChangeDetectionData(vector, reset = false) {
      try {
        await sleep(100)
        this.dataLoading = true
        this.advSearch = false
        const res = await getVector({
          id: vector.aoi_uuid,
          projectId: this.$route.params.id,
          vectorId: vector.uuid,
          payload: {
            feature_id: this.id,
            from_area: this.fromArea,
            to_area: this.toArea,
            type: this.type,
            page: reset ? 1 : this.options.page,
            per_page: this.options.itemsPerPage,
            sort: this.options.sortBy[0]
              ? !this.options.sortDesc[0]
                ? this.options.sortBy[0]
                : `-${this.options.sortBy[0]}`
              : undefined,
          },
        })
        this.features = res.data.data
        this.totalData = res.data.total
        return res
      } catch (e) {
      } finally {
        this.dataLoading = false
      }
    },
    async getLayers() {
      try {
        this.loading = true
        const res = await getChangeDetectionLayer({
          id: this.currentVector.aoi_uuid,
          projectId: this.$route.params.id,
          vectorId: this.currentVector.uuid,
          payload: { qaqc: true },
        })
        res.data.forEach((val, index) => {
          if (index === res.data.length - 1) val.tile_info.name = val.name
          if (val.tile_info.type === 'images') {
            let image = {
              id: val.name,
              display: index === res.data.length - 1,
              data: val.tile_info,
              layerType: 'Raster_Image',
              name: val.name,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
            }
            this.$refs.mapView.addImageToMap(image)
          } else {
            this.$refs.mapView.addVectorTiles({
              isHover: true,
              display: index === res.data.length - 1,
              data: val,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
              layerName: 'default',
              paint: VectorStyle.getStyle(val.tile_info.styles),
              name: val.name,
              id: val.name,
              type: val.tile_info.styles.type,
            })
          }
        })
        if (this.layers[0]) this.$refs.mapView.submitZoom(this.layers[0].bbox)
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async addLayer(feature) {
      let featureDetail = await this.getFeature(feature)
      this.$refs.mapView.displayVector(
        '#ff0015',
        featureDetail.geojson,
        'line',
        '#ff0015',
        'change-id-' + featureDetail.id,
        false,
        'polygon',
      )
      this.$refs.mapView.submitZoom(bbox(featureDetail.geojson))
      await sleep(800)
      this.$refs.mapView.removeLayer('change-id-' + featureDetail.id)
    },
    async getFeature(feature) {
      try {
        this.loading = true
        const res = await getFeature({
          id: this.currentVector.aoi_uuid,
          projectId: this.$route.params.id,
          vectorId: this.currentVector.uuid,
          featureId: feature.id,
          payload: {},
        })
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    transformArea(area) {
      return AreaConvert.covertArea(area, 'ha')
    },
    async editAOI(feature) {
      try {
        this.loading = true
        this.editFeature = feature
        await this.changeMapMode('overlay')
        const res = await this.getFeature(feature)
        this.editing = true
        this.$nextTick(() => {
          this.$refs.mapView.drawAOI({
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: res.geojson,
              },
            ],
          })
          this.$refs.mapView.moveDrawLayerToTop()
        })
      } catch (e) {
        console.log(e)
        this.editing = false
      } finally {
        this.loading = false
      }
    },
    async changeMapMode(mode) {
      if (!this.$refs.layerControl && !this.$refs.map) {
        await sleep(100)
        await this.changeMapMode(mode)
      } else {
        await sleep(500)
        this.$refs.layerControl.mapMode = mode
      }
    },
    async updateShowable(vector) {
      try {
        this.loading = true
        await updateFeature({
          id: this.currentVector.aoi_uuid,
          projectId: this.$route.params.id,
          vectorId: this.currentVector.uuid,
          featureId: vector.id,
          params: {
            geometry: vector.geometry,
            type: vector.properties.type,
            showable: vector.showable,
          },
        })
        // await this.openEditVector()
      } catch (e) {
        console.log(e)
      } finally {
        this.editing = false
        this.loading = false
      }
    },
    async saveFeature() {
      try {
        if (this.$refs.mapView.getAllDrawFeature().length !== 1)
          return this.$store.commit('message/SHOW_ERROR', 'Total of features needed is 1')
        if (this.editing)
          this.$refs.saveForm.openDialog(
            this.currentVector,
            'Update Feature',
            this.$refs.mapView.getAllDrawFeature()[0].geometry,
            this.editFeature,
          )
        else
          this.$refs.saveForm.openDialog(
            this.currentVector,
            'Add Feature',
            this.$refs.mapView.getAllDrawFeature()[0].geometry,
          )
        this.$refs.mapView.resetDraw()
      } catch (e) {
        console.log(e)
      } finally {
        this.isCreating = false
        this.editing = false
        await this.getChangeDetectionData(this.currentVector)
        await this.getLayers()
      }
    },
  },
}
</script>

<style scoped></style>
